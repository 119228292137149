import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { Bars3Icon, ChevronDownIcon, ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline'

import type { User } from '../../user/types'
import { useTranslation } from 'react-i18next'
import { extractInitialsFromFullName, getFullName } from '@shared/helpers/utils'
import { useProfileOwnerQuery } from '../../user/queries'
import { useAuth } from '../../authentication/ui/AuthContainer'
import { publicFolder } from '@shared/helpers/pathBuilder'
import { IconButton } from '@shared/components/base/IconButton'

type Props = {
    toggleSidebar: () => void
}

export function AppBar({ toggleSidebar }: Props) {
    const { data: user = {} as User, isLoading } = useProfileOwnerQuery()
    const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<boolean>(false)

    return (
        <div
            className="fixed top-0 z-20 flex w-full items-center justify-between bg-white px-md shadow-md"
            style={{ height: 'var(--app-bar-height)' }}
        >
            <div className="flex items-center gap-md">
                <IconButton onClick={toggleSidebar}>
                    <Bars3Icon className="size-7" />
                </IconButton>
                <div className="h-6 w-6 md:w-auto">
                    <img
                        className="hidden size-full md:block"
                        src={publicFolder.images.logoColorCaptello}
                        alt="captello-logo"
                    />
                    <img
                        className="block size-full md:hidden"
                        src={publicFolder.images.logoImageOnly}
                        alt="captello-logo"
                    />
                </div>
            </div>

            <div className="flex items-start gap-md">
                {isLoading ? <LoadingSkeletons /> : <UserBasicInfo user={user} />}
                <IconButton onClick={() => setIsOpenDropdownMenu(!isOpenDropdownMenu)}>
                    <ChevronDownIcon className="size-5" />
                </IconButton>

                {isOpenDropdownMenu && <DropdownMenu />}
            </div>
        </div>
    )
}

const UserBasicInfo = ({ user }: { user: User }) => {
    const fullName = getFullName(user.firstName, user.lastName)

    return (
        <>
            <div className="size-11 rounded-full bg-secondary-dark">
                {user.fileUserProfilePicture ? (
                    <ProfilePicture imgUrl={user.fileUserProfilePicture} />
                ) : (
                    <ProfileInitials name={fullName} />
                )}
            </div>

            <div className="hidden flex-col md:flex">
                <span className="font-semibold text-secondary-900">{fullName}</span>
                <span className="text-sm text-secondary-300">{user.title}</span>
            </div>
        </>
    )
}

const LoadingSkeletons = () => {
    return (
        <>
            <Skeleton variant="circular" sx={{ height: '48px', width: '48px' }} />
            <Skeleton variant="rectangular" sx={{ height: '44px', width: '180px' }} />
        </>
    )
}

const ProfilePicture = ({ imgUrl }: { imgUrl: string }) => {
    return <img className="size-full rounded-full" src={imgUrl} alt="userimg" />
}

const ProfileInitials = ({ name }: { name: string }) => {
    return (
        <span className="grid size-full place-items-center rounded-full text-xl font-bold">
            {extractInitialsFromFullName(name)}
        </span>
    )
}

const DropdownMenu = () => {
    const { logout } = useAuth()
    const { t } = useTranslation()

    const items = [
        // { name: 'accountInfo', icon: <UserIcon /> },
        // { name: 'changePassword', icon: <LockClosedIcon /> },
        { name: 'logout', icon: <ArrowLeftStartOnRectangleIcon />, onClick: logout },
    ]

    return (
        <div className="absolute right-6 top-12 grid gap-md rounded-md bg-white p-md shadow-lg">
            {items.map((item, index) => {
                return (
                    <div
                        className="flex cursor-pointer items-center gap-sm bg-transparent p-sm hover:rounded-lg hover:bg-secondary"
                        onClick={item.onClick}
                        key={index}
                    >
                        <span className="size-5">{item.icon}</span>
                        <span className="text-secondary-900">{t(item.name)}</span>
                    </div>
                )
            })}
        </div>
    )
}
